import Link from 'next/link';
import { Block, ButtonLink, createComponent, Flex, For, Icon, IconFA, If, IntrinsicProps, LoaderFill, Title, toClassName } from 'react-commons';

import { DailyArchiveItem, dailyFieldsToDate, dateToDailyFields } from '@/lib/hooks/useDailyArchive';
import { DAILY_GAME_QUERY, DailyGameFields, FullGameData, getDailyGameSrc } from '@/lib/drupal/models/Games';
import { getZonedDate } from '@/lib/util/zonedTime';
import { getThumbnail } from '@/components/DailyArchive';

import style from './index.module.scss';
import { format, addMonths, subMonths, endOfMonth, isSameMonth, addDays, subDays } from 'date-fns';
import SwagModel from '@/lib/swag/models/Swag';
import { useEffect, useState } from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useWeeklyArchive } from '@/lib/hooks/useWeeklyArchive';
import { useIsPremiumUser } from '@/lib/hooks/useIsPremiumUser';
import useSWR from 'swr';
import { EventListener, EventMap } from '@/lib/util/eventListener';
import React from 'react';

function formatDate (fields: DailyGameFields, short?: boolean) {
  const date = dailyFieldsToDate(fields);
  // May 2, 2024
  if (short) {
    return format(date, 'MMM d, yyyy');
  }
  // Sunday, May 2<br />2024
  return format(date, 'EEEE, MMM d') + '<br />' + format(date, 'yyyy');
}

function formatMobileDate (fields: DailyGameFields, short?: boolean) {
  const date = dailyFieldsToDate(fields);
  // May 2, 2024
  if (short) {
    return format(date, 'MMM d, yyyy');
  }
  // Sun, May 2<br />2024
  return format(date, 'EEE, MMM d') + '<br />' + format(date, 'yyyy');
}

export function dailyFieldsToDateKey (fields: DailyGameFields): string {
  return `20${fields.year}-${fields.month.padStart(2, '0')}-${fields.day.padStart(2, '0')}`;
}

export function getHref (
  fields: DailyGameFields, 
  slug: string,
  isToday?: boolean,
  jumpToPlay?: boolean
) {
  const rootSrc = '/gamelanding';

  if (isToday) {
    if (jumpToPlay) {
      return rootSrc + `/${slug}?play=true`;
    }
    return rootSrc + `/${slug}`;
  }

  if (jumpToPlay) {
    return getDailyGameSrc(
      rootSrc + `/${slug}?${DAILY_GAME_QUERY}&play=true`, 
      fields
    );
  }

  return getDailyGameSrc(
    rootSrc + `/${slug}?${DAILY_GAME_QUERY}`, 
    fields
  );
};

export function isToday (fields: DailyGameFields) {
  const today = getZonedDate();
  const date = dailyFieldsToDate(fields);
  return today.toLocaleDateString('en-US') === date.toLocaleDateString('en-US');
};

export function isAfterTomorrow (fields: DailyGameFields) {
  const today = getZonedDate();
  const todayLocaleString = getZonedDate().toLocaleDateString('en-US');
  const tomorrowLocaleString = addDays(getZonedDate(), 1).toLocaleDateString('en-US');

  const itemDate = dailyFieldsToDate(fields);
  const itemLocaleString = itemDate.toLocaleDateString('en-US');

  return todayLocaleString !== itemLocaleString && tomorrowLocaleString !== itemLocaleString && itemDate > today;
};

export const ArchiveContainer = createComponent('ArchiveContainer', { style }, function ArchiveContainer ({ className }, props) {
  return (
    <div className={className}>
      <div className={'ArchiveContainer__Inner'}>
        {props.children}
      </div>
    </div>
  );
});

interface UpsellArchiveTileProps extends IntrinsicProps {
  item: DailyArchiveItem
  contentUrl: string
  href: string
  hasPicture?: boolean
  kind: 'daily' | 'bonus'
}

export const UpsellArchiveTile = createComponent<UpsellArchiveTileProps>('ArchiveTile', { style }, function UpsellArchiveTile ({ className }, props) {

  className = `${className} ${className}--Upsell`;

  return (
    <Link href={props.href}>
      <a className={className}>
        {
          If(props.hasPicture, () => (
            <span className='ArchiveTile__Thumbnail'>
              <img
                src={getThumbnail(props.item.fields, props.contentUrl, props.kind)}
                alt=''
                aria-hidden
              />
            </span>
          )).EndIf()
        }
        <span className='ArchiveTile__Details'>
          <span>{format(dailyFieldsToDate(props.item.fields), 'MMMM do')}</span>
          <span className='ArchiveTile__MobileDate'>Yesterday's Puzzle</span>
        </span>
      </a>
    </Link>
  );

});

interface ArchiveTileProps extends IntrinsicProps {
  item: DailyArchiveItem
  contentUrl: string
  hasPicture?: boolean
  slug: string
  kind: 'daily' | 'bonus'
  state: 'notStarted' | 'inProgress' | 'started' | 'complete'
  isPremiumUser?: boolean
  short?: boolean
}

export const ArchiveTile = createComponent<ArchiveTileProps>('ArchiveTile', { style }, function ArchiveTile ({ mergeClassNames, className }, props) {
  const isFutureItem = isAfterTomorrow(props.item.fields);
  const isTodaysItem = isToday(props.item.fields);

  const isLocked = props.isPremiumUser ? 
    isFutureItem :
    !isTodaysItem;

  className =
    toClassName(className, { 
      [ `state-${props.state}` ]: true,
      [ 'state-locked' ]: isLocked,
      [ 'state-today' ]: isTodaysItem,
      [ 'state-future' ]: isFutureItem
    });

  const tileContent = (
    <>
      <span className='ArchiveTile__MobileDate' dangerouslySetInnerHTML={{ __html: formatMobileDate(props.item.fields, props.short) }} />
      {
        If(props.hasPicture, () => (
          <span className='ArchiveTile__Thumbnail'>
            <img
              src={getThumbnail(props.item.fields, props.contentUrl, props.kind)}
              alt={'thumbnail'}
              width={133}
              height={83}
              aria-hidden
            />
          </span>
        )).EndIf()
      }
      <span className='ArchiveTile__Details'>
        <span dangerouslySetInnerHTML={{ __html: formatDate(props.item.fields, props.short) }} />
        <ArchiveStatusIcon state={isFutureItem ? 'future' : props.state} inverted={isTodaysItem} />
      </span>
    </>
  );

  // Future tiles aren't unlocked for anyone, so don't make them into links
  if (isFutureItem) {
    return (
      <div className={className}>
        { tileContent }
      </div>
    );
  }

  // Otherwise, return either the archive link or upsell link based on locked
  // status
  const href = getHref(
    props.item.fields,
    props.slug,
    isTodaysItem,
    true
  );

  return (
    <Link href={href}>
      <a className={className}>
        { tileContent }
      </a>
    </Link>
  );
});

interface ArchiveProps extends IntrinsicProps {
  game: FullGameData
  swagUUID: string
  entityID: string
}

function getMonthYearFromDate (date: Date) {
  const monthYear = `${date.getMonth().toString().padStart(2, '0')}-${date.getFullYear()}`;

  return monthYear;
}

function getDateFromMonthYear (monthYear: string) {
  const [ month, year ] = monthYear.split('-').map(x => parseInt(x));

  return new Date(year, month, 1);
}

export function getDailyArchiveYears (game: FullGameData) {
  const currentDate = getZonedDate();
  const archiveStartDate = getZonedDate(game.archive.startDate);

  const currentYear = currentDate.getFullYear();
  let y = archiveStartDate.getFullYear();

  const years: number[] = [];

  while (y <= currentYear) {
    years.push(y);
    ++y;
  }

  return years;
}

interface ArchiveTilePlaceholderProps extends IntrinsicProps {
  hasPicture?: boolean
}

export const ArchiveTilePlaceholder = createComponent<ArchiveTilePlaceholderProps>('ArchiveTilePlaceholder', { style, classStates: [ 'hasPicture' ] }, function ArchiveTilePlaceholder ({ className }, props) {
  return (
    <div className={className}>
      <span className='DatePlaceholder__Mobile'>&nbsp;</span>

      {
        If(props.hasPicture, () => (
          <div className='ArchiveTilePlaceholder__Image' />
        )).Else(() => (
          <span className='DatePlaceholder'>&nbsp;</span>
        )).EndIf()
      }

      <span className='IconPlaceholder' />
    </div>
  );
});

export const DailyArchive = createComponent<ArchiveProps>('DailyArchive', { style }, function DailyArchive ({ className }, props) {
  const items: DailyArchiveItem[] = [];
  const currentDate = getZonedDate();
  const [ isPremiumUser, isPremiumUserLoading ] = useIsPremiumUser();

  const DAYS_COUNT = 15;

  for (let i = 0; i < DAYS_COUNT; i++) {
    const date = subDays(currentDate, i);
    items.push({
      fields: dateToDailyFields(date),
    });
  }

  const key = SwagModel.getRecentArchiveProgressKey(props.swagUUID, props.entityID);

  const { data, error, isLoading } = useSWR(key, SwagModel.archiveProgressFetcher);

  return (
    <div className={className}>
      <Block>
        {
          If(isPremiumUserLoading, () => (
            <LoaderFill />
          )).Else(() => (
            <ArchiveContainer>
              {
                For(items, (item, index) => (
                  <ArchiveTile
                    key={index}
                    item={item}
                    contentUrl={props.game.archive.contentUrl}
                    hasPicture={props.game.archive.display === 'picture'}
                    kind={props.game.isBonusGame ? 'bonus' : 'daily'}
                    isPremiumUser={isPremiumUser}
                    state={data?.[ dailyFieldsToDateKey(item.fields) ] ?? 'notStarted'}
                    slug={props.game.href.replace('/gamelanding/', '')}
                  />
                ))
              }
            </ArchiveContainer>
          )).EndIf()
        }
      </Block>
    </div>
  );
});

export type ArchiveEvents = EventMap & {
  'selectYear': (event: { year: number }) => void
  'nextYear': (event: {}) => void
  'prevYear': (event: {}) => void
  'firstYear': (event: {}) => void
  'lastYear': (event: {}) => void
}

class ArchiveEventListener extends EventListener<ArchiveEvents> {}
export const archiveEventListener = new ArchiveEventListener();

export const DailyArchiveByMonth = createComponent<ArchiveProps>('DailyArchiveByMonth', { style }, function DailyArchiveByMonth ({ slots, className }, props) {
  const items: DailyArchiveItem[] = [];

  // The archive displays content based solely on month and year, so only track
  // those values rather than trying to use a full date object.
  const [ monthYear, setMonthYear ] = useState(getMonthYearFromDate(getZonedDate()));
  const years = getDailyArchiveYears(props.game);
  const currentDate = getDateFromMonthYear(monthYear);
  const lastDay = endOfMonth(currentDate).getDate();
  const [ isPremiumUser, isPremiumUserLoading ] = useIsPremiumUser();

  for (let i = 1; i <= lastDay; i++) {
    const date = new Date(currentDate);
    date.setDate(i);

    items.push({
      date,
      fields: dateToDailyFields(date),
    });
  }

  const key = SwagModel.getArchiveProgressByMonthKey(props.swagUUID, currentDate.getMonth() + 1, currentDate.getFullYear(), props.entityID);

  const { data, error, isLoading } = useSWR(key, SwagModel.archiveProgressFetcher);

  const prevMonth = () => {
    setMonthYear(getMonthYearFromDate(subMonths(currentDate, 1)));
  };

  const nextMonth = () => {
    setMonthYear(getMonthYearFromDate(addMonths(currentDate, 1)));
  };

  useEffect(() => {
    const selectYearHandler = archiveEventListener.on('selectYear', (event) => {
      const date = new Date();
      date.setFullYear(event.year);
      setMonthYear(getMonthYearFromDate(date));
    });

    const nextYearHandler = archiveEventListener.on('nextYear', () => {
      const currentYear = currentDate.getFullYear();
      const maxYear = years[ years.length - 1 ];
      if (currentYear + 1 >= maxYear) {
        return;
      }
      setMonthYear(getMonthYearFromDate(addMonths(currentDate, 12)));
    });

    const prevYearHandler = archiveEventListener.on('prevYear', () => {
      const currentYear = currentDate.getFullYear();
      const minYear = years[ 0 ];
      if (currentYear - 1 <= minYear) {
        return;
      }
      setMonthYear(getMonthYearFromDate(subMonths(currentDate, 12)));
    });

    const firstYearHandler = archiveEventListener.on('firstYear', () => {
      const date = new Date();
      date.setFullYear(years[ 0 ]);
      setMonthYear(getMonthYearFromDate(new Date()));
    });

    const lastYearHandler = archiveEventListener.on('lastYear', () => {
      const date = new Date();
      date.setFullYear(years[ years.length - 1 ]);
      setMonthYear(getMonthYearFromDate(date));
    });

    return () => {
      archiveEventListener.off('selectYear', selectYearHandler);
      archiveEventListener.off('nextYear', nextYearHandler);
      archiveEventListener.off('prevYear', prevYearHandler);
      archiveEventListener.off('firstYear', firstYearHandler);
      archiveEventListener.off('lastYear', lastYearHandler);
    };
  });

  const archiveStartDate = getZonedDate(props.game.archive.startDate);
  const isFirstMonth = 
    currentDate.getMonth() === archiveStartDate.getMonth() && 
    currentDate.getFullYear() === archiveStartDate.getFullYear();

  const isCurrentMonth = 
    isSameMonth(currentDate, getZonedDate()) && 
    currentDate.getFullYear() === getZonedDate().getFullYear();

  const isBeforeStartDate = (itemDate: Date) => {
    return itemDate < archiveStartDate;
  };

  return (
    <div className={className}>
      {
        If(slots?.title, () => (
          <Block className='--marMd1__t'>
            <Title h1 size3>{slots.title}</Title>
            <Flex justifyCenter className='--hideDesktop'>
              <ButtonLink href={props.game.href} rounded secondary small style={{ height:'1.5rem' }}>
                <span>Go Back</span>
              </ButtonLink>
            </Flex>
          </Block>
        )).EndIf()
      }

      <Block>
        <Flex justifySpaceBetween alignCenter>
          <a onClick={prevMonth} data-disabled={isFirstMonth}><IconFA small icon={faChevronLeft} /></a>

          <div>
            <p>{format(currentDate, 'MMMM yyyy')}</p>
          </div>

          <a onClick={nextMonth} data-disabled={isCurrentMonth}><IconFA small icon={faChevronRight} /></a>
        </Flex>
      </Block>

      <Block>
        {
          If(isPremiumUserLoading, () => (
            <LoaderFill />
          )).Else(() => (
            <ArchiveContainer>
              {
                For(items, (item, index) => (
                  <React.Fragment key={index}>
                    {
                      If(isBeforeStartDate(item.date), () => (
                        <ArchiveTilePlaceholder hasPicture={props.game.archive.display === 'picture'} />
                      )).Else(() => (
                        <ArchiveTile
                          item={item}
                          contentUrl={props.game.archive.contentUrl}
                          hasPicture={props.game.archive.display === 'picture'}
                          kind={props.game.isBonusGame ? 'bonus' : 'daily'}
                          isPremiumUser={isPremiumUser}
                          state={ data?.[ dailyFieldsToDateKey(item.fields) ] ?? 'notStarted'}
                          slug={props.game.href.replace('/gamelanding/', '')}
                          short
                        />
                      )).EndIf()
                    }
                  </React.Fragment>
                ))
              }
            </ArchiveContainer>
          )).EndIf()
        }
      </Block>

      <Block className='--hideMobile'>
        <Flex justifyCenter>
          <ButtonLink href={props.game.href} rounded secondary>
            <span>Go Back</span>
          </ButtonLink>
        </Flex>
      </Block>
    </div>
  );
});

export const WeeklyArchive = createComponent<ArchiveProps>('WeeklyArchive', { style }, function WeeklyArchive (context, props) {

  const slots = context?.slots ?? {};

  const {
    rows,
    page,
    numPages,
    changePage
  } = useWeeklyArchive({
    archive: props.game.archive,
  });

  const items = rows.flat();

  const key = SwagModel.getArchiveProgressByDateRangeKey(props.swagUUID, props.entityID, items?.[ 0 ]?.fields, items?.[ items.length - 1 ]?.fields);

  const { data, error, isLoading } = useSWR(key, SwagModel.archiveProgressFetcher);

  const tiles = [];
  
  for (let i = items.length - 1; i > -1; i--) {
    tiles.push(
      <ArchiveTile
        key={i}
        item={items[ i ]}
        contentUrl={props.game.archive.contentUrl}
        hasPicture={props.game.archive.display === 'picture'}
        kind='daily'
        state={data?.[ dailyFieldsToDateKey(items[ i ].fields) ] ?? 'notStarted'}
        slug={props.game.href.replace('/gamelanding/', '')}

      />
    );
  }

  return (
    <div className={context.className}>
      <Block className='--marMd1__t'>
        <Title h1 size3>{slots?.title}</Title>
      </Block>
      <Block>
        <Flex justifySpaceBetween alignCenter>
          <a onClick={() => changePage(page + 1)} data-disabled={page === numPages()}><IconFA small icon={faChevronLeft} /></a>
          <a onClick={() => changePage(page - 1)} data-disabled={page === 0}><IconFA small icon={faChevronRight} /></a>
        </Flex>
      </Block>
      
      <Block>
        <ArchiveContainer>
          { tiles }
        </ArchiveContainer>
      </Block>
    </div>
  );
});

interface ArchiveStatusIconProps extends IntrinsicProps {
  state?: 'notStarted' | 'inProgress' | 'started' | 'complete' | 'future'
  inverted?: boolean
}

const ArchiveStatusIcon = createComponent<ArchiveStatusIconProps>('ArchiveStatusIcon', { style }, function ArchiveStatusIcon ({ className, slots }, props) {

  className =
    toClassName(className, { 
      [ `state-${props.state}` ]: true,
      [ 'state-inverted' ]: props.inverted
    });

  let icon;

  switch(props.state) {
  case 'inProgress':
  case 'started':
    icon = (
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1269 24.1604C8.92111 24.1604 5.90643 22.9118 3.64024 20.6441C-1.04024 15.9636 -1.04024 8.34964 3.64024 3.66916C5.90643 1.40297 8.92111 0.154419 12.1269 0.154419C15.3327 0.154419 18.3474 1.40297 20.6135 3.67075C22.8813 5.93853 24.1299 8.95162 24.1299 12.1574C24.1299 15.3632 22.8813 18.3779 20.6135 20.6441C18.3474 22.9118 15.3327 24.1604 12.1269 24.1604ZM12.1269 1.74696C9.34632 1.74696 6.73137 2.82989 4.76617 4.79668C0.706782 8.85606 0.706782 15.4603 4.76617 19.5197C6.73296 21.4865 9.34632 22.5694 12.1269 22.5694C14.9075 22.5694 17.5224 21.4865 19.4876 19.5197C21.4528 17.5529 22.5373 14.9396 22.5373 12.159C22.5373 9.37842 21.4544 6.76347 19.4876 4.79827C17.5208 2.83148 14.9075 1.74855 12.1269 1.74855V1.74696Z" fill="currentColor"/>
        <path d="M18.0813 18.1103C21.3699 14.8217 21.3699 9.49148 18.0813 6.20288L6.17383 18.1103C9.46243 21.3989 14.7927 21.3989 18.0813 18.1103Z" fill="currentColor"/>
      </svg>
    );

    break;

  case 'complete':
    icon = (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 24C5.38305 24 0 18.617 0 12C0 5.38304 5.38305 0 12 0C18.617 0 24 5.38304 24 12C24 18.617 18.617 24 12 24ZM12 1.59215C6.26191 1.59215 1.59215 6.26032 1.59215 12C1.59215 17.7397 6.26032 22.4079 12 22.4079C17.7397 22.4079 22.4079 17.7397 22.4079 12C22.4079 6.26032 17.7381 1.59215 12 1.59215Z" fill="currentColor"/>
        <path d="M11.9997 3.5824C7.35064 3.5824 3.58203 7.35101 3.58203 12.0001C3.58203 16.6491 7.35064 20.4177 11.9997 20.4177C16.6488 20.4177 20.4174 16.6491 20.4174 12.0001C20.4174 7.35101 16.6488 3.5824 11.9997 3.5824ZM11.8962 16.0537L10.2006 17.7493L8.50495 16.0537L5.26971 12.8184L6.96534 11.1228L10.2006 14.358L17.0309 7.52773L18.7265 9.22337L11.8962 16.0537Z" fill="currentColor"/>
      </svg>
    );

    break;

  default:
    icon = (
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12.1299" cy="12.1573" r="11" stroke="currentColor" strokeWidth="2"/>
      </svg>
    );

    break;
  }

  return (
    <Icon className={className}>
      { icon }
    </Icon>
  );
});
